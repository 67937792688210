import React from 'react';
import { ImageSizer } from 'js/helpers/ImageSizer';
import { ImageOutput } from 'js/model/rainbow/ImageOutput';
import { fetchVenuePortfolioImageMetadata } from 'js/service/venueService';
import { RequestData } from 'js/helpers/service';
import { VenueOutput } from 'js/model/rainbow/venue/VenueOutput';
import { PortfolioImageMetadata } from 'js/model/rainbow/venue/PortfolioImageMetadata';
import { PortfolioImagePopup } from 'js/pages/VenuePage/PortfolioImagePopup/PortfolioImagePopup';
import { CmsOurWork } from 'js/model/cms/cms-venue-page';
import {
  getFragmentValue,
  objToQuery,
  queryToObj,
  updateUriWithOpenPopup,
} from 'js/helpers/uri-util';
import { ServiceSection } from '../ServiceSection';
import styles from './ServiceImages.module.css';

const MAX_IMAGES = 6;

interface Props {
  images: ImageOutput[];
  serviceId: string;
  pageData: RequestData & { venue?: { venue: VenueOutput } };
  venueName: string;
  header: string;
  cms: CmsOurWork;
}

export function ServiceImages({
  serviceId,
  images,
  pageData,
  venueName,
  header,
  cms,
}: Props): React.ReactElement {
  const [containerWidth, setContainerWidth] = React.useState<number>(
    window.innerWidth
  );
  const [imagesMetadata, setImagesMetadata] = React.useState<
    PortfolioImageMetadata[]
  >([]);
  const [matchingImages, setMatchingImages] = React.useState<ImageOutput[]>([]);
  const [selectedImage, setSelectedImage] = React.useState<ImageOutput>();

  const onResize = (): void => {
    setContainerWidth(window.innerWidth);
  };

  React.useEffect(() => {
    loadImagesMetadata();
    window.addEventListener('resize', onResize);
    window.addEventListener('popstate', onPopState);
    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('popstate', onPopState);
    };
  }, []);

  React.useEffect(() => {
    getMatchingServiceImages();
  }, [imagesMetadata]);

  React.useEffect(() => {
    openPopupFromUri();
  }, [matchingImages]);

  if (matchingImages.length === 0) {
    return <></>;
  }

  async function loadImagesMetadata(): Promise<void> {
    const imagesMetadata = await fetchVenuePortfolioImageMetadata(
      pageData,
      pageData.venue!.venue.id,
      images
    );

    setImagesMetadata(imagesMetadata ?? []);
  }

  function getMatchingServiceImageIds(): number[] {
    let matchedImages = 0;
    const serviceImages: number[] = [];
    for (let i = 0; i < imagesMetadata.length; i++) {
      if (matchedImages === MAX_IMAGES) {
        break;
      }

      // The service id from our work api comes in form of 'srvc_11'.
      // but we need only the numeric part
      if (imagesMetadata[i].service?.id.split('srvc_')[1] === serviceId) {
        serviceImages.push(imagesMetadata[i].imageId.id);
        matchedImages++;
      }
    }
    return serviceImages;
  }

  function getMatchingServiceImages(): void {
    const ids = getMatchingServiceImageIds();
    const matchingImages = images.filter(image => ids.includes(image.id));
    setMatchingImages(matchingImages);
  }

  const renderImages = (image: ImageOutput): React.ReactNode => {
    const imageSizer = new ImageSizer(image.uris);
    const url = imageSizer.getBestImage(1.5 * containerWidth, false);
    const imageIds = matchingImages.map(img => img.id).join(',');

    return (
      <img
        src={url}
        key={image.id}
        className={styles.image}
        onClick={() => {
          setSelectedImage(image);
          updateUriWithOpenPopup({
            portfolioImage: image.id,
            imageIds,
          });
        }}
      />
    );
  };

  function onPopState(): void {
    if (
      getFragmentValue('portfolioImage') !== undefined &&
      selectedImage === undefined &&
      getFragmentValue('imageIds') !== undefined
    ) {
      openPopupFromUri();
    } else if (getFragmentValue('portfolioImage') === undefined) {
      setSelectedImage(undefined);
    }
  }

  function openPopupFromUri(): void {
    const imageId = getFragmentValue('portfolioImage');
    const image = images.find(image => image.id.toString() === imageId);

    if (!image) {
      return;
    }

    setSelectedImage(image);
  }

  function onPopupClose(): void {
    const { pathname, hash, search } = window.location;
    const newParams = { ...queryToObj(hash) };
    delete newParams.portfolioImage;
    delete newParams.imageIds;
    window.history.pushState(
      newParams,
      '',
      pathname + search + objToQuery(newParams, '#')
    );
    setSelectedImage(undefined);
  }

  const headerText = header.replace('{0}', venueName);

  return (
    <>
      <ServiceSection header={headerText}>
        <div className={styles.imagesContainer}>
          {matchingImages.map(renderImages)}
        </div>
      </ServiceSection>
      {selectedImage && (
        <PortfolioImagePopup
          pageData={pageData}
          images={matchingImages}
          initiallySelectedImage={selectedImage}
          onClose={onPopupClose}
          cms={cms}
        />
      )}
    </>
  );
}
