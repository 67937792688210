import React from 'react';
import { Popup } from 'js/components/Popup/Popup';
import { FooterBoundary, PopupContent } from 'js/components/Popup/PopupContent';
import {
  Box,
  ColorPairTeal,
  EmployeeBadge,
  IconStarFilled,
  Inline,
  MediaObject,
  Stack,
  Text,
  TreatmentTypeBadge,
  useViewport,
  Viewport,
} from '@treatwell/ui';
import { ButtonColour } from 'js/components/Button';
import { ButtonAnchor } from 'js/components/ButtonAnchor/ButtonAnchor';
import { fetchVenuePortfolioImageMetadata } from 'js/service/venueService';
import { RequestData } from 'js/helpers/service';
import { VenueOutput } from 'js/model/rainbow/venue/VenueOutput';
import {
  PortfolioImageMetadata,
  Reviews,
} from 'js/model/rainbow/venue/PortfolioImageMetadata';
import { ImageOutput } from 'js/model/rainbow/ImageOutput';
import { CmsOurWork } from 'js/model/cms/cms-venue-page';
import { PriceDisplay } from 'js/components/PriceDisplay/PriceDisplay';
import {
  extractLanguageCodeFromPath,
  getFragmentValue,
  updateUriWithOpenPopup,
} from 'js/helpers/uri-util';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { getItemByServiceId } from 'js/helpers/venue-menu';
import { PatchTestWarning } from './PatchTestWarning/PatchTestWarning';
import { Gallery } from './Gallery/Gallery';
import styles from './PortfolioImagePopup.module.css';
import { getDateTimeUri } from '../VisitConfiguratorBar/getDateTimeUri';
import { PARAM_DATE, PARAM_START_TIME } from '../uri';
import { SelectedServices } from '../VisitConfiguratorBar/VisitConfiguratorBar';
import { SelectTreatmentButton } from '../SelectTreatmentButton/SelectTreatmentButton';

interface Props {
  pageData: RequestData & { venue?: { venue: VenueOutput } };
  images: ImageOutput[];
  initiallySelectedImage: ImageOutput;
  onClose: () => void;
  cms: CmsOurWork;
  closeOurWorkModal?: () => void;
  isOurWorkModalOpen?: boolean;
}

export function PortfolioImagePopup({
  pageData,
  images,
  initiallySelectedImage,
  onClose,
  cms,
  closeOurWorkModal,
  isOurWorkModalOpen,
}: Props): React.ReactElement {
  const isMobile = useViewport({ device: 'mobile' });
  const portfolioImageMetadataItems = React.useRef<{
    [key: number]: PortfolioImageMetadata;
  }>({});
  const [
    currentPortfolioImageMetadata,
    setCurrentPortfolioImageMetadata,
  ] = React.useState<PortfolioImageMetadata>();
  const [
    isPatchTestWarningExpanded,
    setIsPatchTestWarningExpanded,
  ] = React.useState<boolean>(false);
  const [selectedImage, setSelectedImage] = React.useState<ImageOutput>(
    initiallySelectedImage
  );
  const [galleryImages, setGalleryImages] = React.useState<ImageOutput[]>(
    images
  ); // In case we open the popup from the service details popup, we need to take the ids of the images from the url
  const isOurWorkExperimentActive =
    useFeatureValue('ff-web-venue-our_work_above_menu', 'none') === 'variant-1';

  async function loadPortfolioImageMetadata(): Promise<void> {
    if (!portfolioImageMetadataItems.current[selectedImage.id]) {
      const portfolioImageMetadata =
        (await fetchVenuePortfolioImageMetadata(
          pageData,
          pageData.venue!.venue.id,
          [selectedImage]
        )) ?? [];
      portfolioImageMetadataItems.current[selectedImage.id] =
        portfolioImageMetadata[0];
    }
    setCurrentPortfolioImageMetadata(
      portfolioImageMetadataItems.current[selectedImage.id]
    );
  }

  React.useEffect(() => {
    window.addEventListener('popstate', onPopState);
    return () => window.removeEventListener('popstate', onPopState);
  }, []);

  React.useEffect(() => {
    loadPortfolioImageMetadata();

    // Scroll popup's content to top after selected image has changed.
    let timeout: NodeJS.Timeout;
    function scrollToTop(): void {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        const popupContent = document.querySelector(
          '[class^="PopupContent-module--content"]'
        );
        if (popupContent && popupContent.scrollTop !== 0) {
          popupContent.scroll({ top: 0, behavior: 'smooth' });
        }
      }, 0);
    }

    scrollToTop();
  }, [selectedImage.id]);

  const { service, employee } = currentPortfolioImageMetadata ?? {};

  function getServiceSectionHeader(): string {
    if (service?.serviceOptions?.length) {
      const optionNames = service.serviceOptions
        .filter(option => option.name?.length !== 0)
        .map(option => option.name)
        .join(', ');
      return optionNames.length
        ? `${service.name} - ${optionNames}`
        : service.name;
    }
    return service?.name ?? '';
  }

  function onGalleryImageClick(imageId: number): void {
    updateUriWithOpenPopup({
      portfolioImage: imageId,
    });

    const image = images.find(image => image.id === imageId);
    if (image) {
      setSelectedImage(image);
    }
  }

  function onPopState(event: PopStateEvent): void {
    const { portfolioImage, imageIds } = event.state || {};
    if (imageIds) {
      const matchingImages = images.filter(image =>
        imageIds.includes(image.id)
      );
      setGalleryImages(matchingImages);
    }
    const image = images.find(image => image.id === portfolioImage);

    if (image) {
      setSelectedImage(image);
    }
  }

  function HeaderAndSubheader({
    header,
    subheader,
  }: {
    header: string;
    subheader?: string;
  }): React.ReactElement {
    const bullet = '\u2022';
    return (
      <Text>
        <Text type="smHeader" className={styles.header}>
          {header}
        </Text>

        {subheader && (
          <Text style={{ whiteSpace: 'nowrap' }}>
            <Text>{bullet}</Text>
            <Text className={styles.subHeader}>{subheader}</Text>
          </Text>
        )}
      </Text>
    );
  }

  function ServiceSection(): React.ReactElement {
    if (service === undefined) {
      return <></>;
    }

    const icon = (
      <TreatmentTypeBadge
        colorPair={ColorPairTeal}
        id={service.treatmentTypeId.id}
        size="lg"
      />
    );

    return (
      <Section
        icon={icon}
        title={cms['service-title']}
        header={getServiceSectionHeader()}
        subheader={service.duration}
        reviews={service.reviews}
      />
    );
  }

  function EmployeeSection(): React.ReactElement {
    if (employee === undefined) {
      return <></>;
    }

    const icon = (
      <EmployeeBadge
        colorPair={ColorPairTeal}
        size="lg"
        employee={{ name: employee.name, thumbnailUrl: employee.thumbnail }}
      />
    );

    return (
      <Section
        icon={icon}
        title={cms['employee-title']}
        header={employee.name}
        subheader={employee.category}
        reviews={employee.reviews}
      />
    );
  }

  function ReviewInformation({
    reviews,
  }: {
    reviews?: Reviews;
  }): React.ReactElement {
    if (reviews === undefined) {
      return <></>;
    }
    const label =
      reviews.count > 1 || reviews.count === 0 ? cms.plural : cms.singular;

    const reviewsCountLabel = label.replace('{0}', reviews.count.toString());
    return (
      <MediaObject align="center" space="xs">
        <IconStarFilled size={16} className={styles.star} />
        <Inline space="xs">
          <Text type="bodyHeavy" className={styles.reviews}>
            {reviews.displayAverage}
          </Text>
          <Text type="caption" className={styles.reviewsLabel}>
            ({reviewsCountLabel})
          </Text>
        </Inline>
      </MediaObject>
    );
  }

  function renderBookingCard(): React.ReactElement | null {
    const { venue, channel } = pageData;

    if (!service || !employee || !venue) {
      return null;
    }

    const { venue: venueData } = venue;
    const { id, serviceOptions } = service;

    if (
      !serviceOptions ||
      serviceOptions.length === 0 ||
      serviceOptions.length > 1
    ) {
      return null;
    }

    const serviceOptionId = serviceOptions[0].id.split('sopt_')[1];
    const serviceId = id.split('srvc_')[1];
    const { menuGroup, item } = getItemByServiceId(
      serviceId,
      venueData.menu.menuGroups
    );

    if (!menuGroup || !item) {
      return null;
    }

    const option = item.data.optionGroups
      .flatMap(optionGroup => optionGroup.options)
      .find(option => option.id === serviceOptionId);

    if (!option) {
      return null;
    }

    const groupId = menuGroup.id;
    const itemId = item.data.id;
    const selectedServices: SelectedServices = {
      [groupId]: {
        [itemId]: {
          [serviceOptionId]: true,
        },
      },
    };

    const language = extractLanguageCodeFromPath(
      window.location.pathname,
      channel.code
    );
    const bookLinkWithParams = getDateTimeUri({
      venueId: venueData.id,
      selected: selectedServices,
      date: getFragmentValue(PARAM_DATE),
      timeFrom: getFragmentValue(PARAM_START_TIME),
      language,
    });

    const onChangeHandler = () => {
      onClose();
      if (isOurWorkModalOpen) {
        closeOurWorkModal?.();
      }
    };

    return (
      <Box paddingWide="md" data-cy="service-box">
        <Stack space="md">
          {service.requiresPatchTest && (
            <PatchTestWarning
              cms={cms}
              isExpanded={isPatchTestWarningExpanded}
              onCTAClick={() => {
                setIsPatchTestWarningExpanded(true);
              }}
            />
          )}
          <Inline space="md" align="start" justify="between">
            <Text type="bodyHeavy">
              {cms['book-with']} {employee.name}
            </Text>
            {service?.priceRange && (
              <PriceDisplay
                priceRangeOutput={service.priceRange}
                channelOutput={pageData.channel}
                servicePriceRangeCms={{
                  saveUpTo: cms['save-up-to'],
                  save: cms.save,
                  from: cms.from,
                  perPerson: undefined,
                }}
              />
            )}
          </Inline>
          {isOurWorkExperimentActive ? (
            <SelectTreatmentButton
              menuGroupId={groupId}
              menuItemId={itemId}
              menuOptionId={serviceOptionId}
              primaryTreatmentCategoryGroupId={
                item.data.primaryTreatmentCategoryGroupId
              }
              onChange={onChangeHandler}
            />
          ) : (
            <ButtonAnchor
              label={cms['book-button-title']}
              href={bookLinkWithParams}
              colour={ButtonColour.Orange}
            />
          )}
        </Stack>
      </Box>
    );
  }

  function Section({
    icon,
    title,
    header,
    subheader,
    reviews,
  }: {
    icon: React.ReactElement;
    title: string;
    header: string;
    subheader?: string;
    reviews?: Reviews;
  }): React.ReactElement {
    return (
      <Stack space="md">
        <Text type="mdHeader" className={styles.title}>
          {title}
        </Text>
        <MediaObject align="start">
          {icon}
          <Stack>
            <HeaderAndSubheader header={header} subheader={subheader} />
            <ReviewInformation reviews={reviews} />
          </Stack>
        </MediaObject>
      </Stack>
    );
  }

  function renderPopupContentContainer(): React.ReactElement {
    return (
      <>
        <div className={styles.imageContainer}>
          <img className={styles.image} src={selectedImage.uris['800x1066']} />
        </div>
        {currentPortfolioImageMetadata && (
          <div className={styles.infoContainer}>
            <Stack space="xxl">
              <ServiceSection />
              <EmployeeSection />
              {renderBookingCard()}
            </Stack>
          </div>
        )}
      </>
    );
  }

  function renderContent(): React.ReactElement {
    return (
      <>
        <Viewport device={['desktop', 'tablet']}>
          <Stack className={styles.content}>
            <Inline space="md" className={styles.container} justify="between">
              {renderPopupContentContainer()}
            </Inline>
          </Stack>
        </Viewport>
        <Viewport device="mobile" serverRender>
          <Stack>
            <Stack space="md" className={styles.container}>
              {renderPopupContentContainer()}
            </Stack>
            {isMobile && images.length > 1 && (
              <div className={styles.mobileGalleryContainer}>
                {renderGallery()}
              </div>
            )}
          </Stack>
        </Viewport>
      </>
    );
  }

  function renderGallery(): React.ReactNode {
    return (
      <Gallery
        cms={cms}
        pageData={pageData}
        images={galleryImages}
        selectedImage={selectedImage}
        onImageClick={imageId => {
          onGalleryImageClick(imageId);

          setIsPatchTestWarningExpanded(false);
        }}
      />
    );
  }

  return (
    <Popup
      onCloseRequest={() => {
        onClose();
      }}
      isAlwaysFullscreen
    >
      <PopupContent
        header={{
          mobileOnly: false,
          closeText: 'Close',
          onClose,
          icon: { size: 24 },
          isTransparent: true,
        }}
        footer={{
          content: !isMobile && images.length > 1 && renderGallery(),
          boundary: FooterBoundary.Line,
        }}
      >
        {renderContent()}
      </PopupContent>
    </Popup>
  );
}
